import { Trans } from "next-i18next";
import Link from "next/link";
import { StyledPreferencesMenuListItem } from "./PreferencesMenu.style";

type PreferencesMenuListItemProps = {
	link: string;
	label: string;
	isActive: boolean;
};

const PreferencesMenuListItem = ({
	link,
	label,
	isActive,
}: PreferencesMenuListItemProps) => {
	return (
		<Link href={link} prefetch={false} legacyBehavior>
			<StyledPreferencesMenuListItem active={isActive}>
				<a href={link}><Trans>{label}</Trans></a>
			</StyledPreferencesMenuListItem>
		</Link>
	);
};

export default PreferencesMenuListItem;
