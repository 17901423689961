import { PreferencesMenuProps } from "@bptypes/preferences";

export const PREFERENCES_MENU: PreferencesMenuProps[] = [
	{
		label: "Account.Profile",
		href: "/account/profile",
	},
	{
		label: "Account.GenrePreferences",
		href: "/account/genre",
	},
	{
		label: "Account.Password.IdentityPassword",
		href: "/account/identity",
	},
	{
		label: "Billing.BillingInformation",
		href: "/account/billing",
	},
	{
		label: "Account.Email.Preferences",
		href: "/account/email",
	},
	{
		label: "Account.DownloadPreferences",
		href: "/account/audio-format",
	},
	{
		label: "Cart.Preferences",
		href: "/account/carts",
	},
	// ! Left this page out for now, just in case we need it in the future. This renders the `CookiesContent` component.
	// ! If not needed any longer, please remove and delete the `CookiesContent` component.
	// {
	//   label: "Cookies",
	//   href: "/account/cookies",
	// },
];
