import { NextPageWithLayout } from "@bptypes/layout";
import Homepage from "@components/pages/homepage/Homepage";

import { MainLayout } from "@layouts/index";
import { exportAnonSession, useSessionContext } from "@lib/context/session";
import {
	getPageModuleItemsQuery,
	getPageModulesQuery,
} from "@lib/network/pageModule";
import { getTopReleasesQuery } from "@lib/network/releases";
import { getTopTracksQuery } from "@lib/network/tracks";
import { getLocationData } from "@lib/utils/getLocationData";
import { PageModule, PageModuleItem } from "@models/PageModule";
import { PaginatedResponse } from "@models/generics";
import { Release } from "@models/release";
import { TopTracksResponse } from "@models/track";
import {
	QueryClient,
	dehydrate,
	useQueries,
	useQuery,
} from "@tanstack/react-query";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { ReactElement } from "react";

const pageModuleTypes = [
	"homeLargeSlideshowOld",
	"homeSmallSlideshow",
	"homeCrossMerchandising",
	"releaseFeature",
	"chartFeature",
	"hypeFeature",
];

interface Props {
	pageModules: PageModule[];
	anonSession: AnonSession;
}

const Home: NextPageWithLayout<Props> = (props) => {
	const { pageModules, anonSession } = props;
	const homepageModules: Record<string, PageModuleItem[]> = {};
	const { getAccessToken, importAnonSession } = useSessionContext();
	importAnonSession(anonSession);
	const accessToken = getAccessToken();

	const { data: top10 } = useQuery<TopTracksResponse>(
		getTopTracksQuery({ params: { per_page: 10 }, accessToken, number: 10 }),
	);

	const { data: hypeTop10 } = useQuery<TopTracksResponse>(
		getTopTracksQuery({ params: { per_page: 10, hype: true }, accessToken, number: 10 }),
	);

	const { data: top10Releases } = useQuery(
		getTopReleasesQuery({ params: { per_page: 10 }, accessToken, number: 10 }),
	);

	const { data: pageData } = useQuery<PaginatedResponse<PageModule>>(
		getPageModulesQuery({
			params: {
				page_id: 1,
			},
			accessToken,
		}),
	);

	if (pageModules.length === 0) {
		// filter results and extract modules that we will use on homepage
		if (pageData && pageData.results && pageData.results.length > 0) {
			pageData.results.forEach((module) => {
				if (
					module.position === 0 &&
					pageModuleTypes.includes(module.type.name)
				) {
					pageModules.push(module);
				}
			});
		}
	}

	const moduleItems = useQueries({
		queries: pageModules.map((pageModule) => {
			homepageModules[pageModule.type.name] = [];
			return getPageModuleItemsQuery({ id: pageModule.id, accessToken });
		}),
	});

	// loop through each module and assign moduleItems
	for (let i = 0; i < pageModules.length; i++) {
		const pageModule = pageModules.at(i);
		const pageModuleItems = moduleItems.at(i);
		if (pageModule && pageModuleItems && pageModuleItems.data) {
			homepageModules[pageModule.type.name] = pageModuleItems.data.results;
		}
	}

	return (
		<Homepage
			newOnBeatport={homepageModules["homeLargeSlideshowOld"] || []}
			djCharts={homepageModules["homeSmallSlideshow"] || []}
			newReleases={homepageModules["releaseFeature"] || []}
			featuredCharts={homepageModules["chartFeature"] || []}
			hypePicks={homepageModules["hypeFeature"] || []}
			marketingPromotions={homepageModules["homeCrossMerchandising"] || []}
			top10={top10?.results || []}
			hypeTop10={hypeTop10?.results || []}
			top10Releases={top10Releases?.results || []}
		/>
	);
};

export default Home;

Home.getLayout = (page: ReactElement<Props>) => {
	return (
		<MainLayout
			title="Beatport | DJ & Electronic Dance Music, Tracks & Mixes"
			metaDescription="Beatport is the world's largest DJ store: download and stream electronic dance music. Explore the new and exclusive music tracks out of the best electronic dance music. "
			hasDesktopSidebar
			noSuffix
		>
			{page}
		</MainLayout>
	);
};

export async function getServerSideProps(ctx: any) {
	const queryClient = new QueryClient();
	const pageModules: PageModule[] = [];
	const location = getLocationData(ctx.req);

	// get all page data for homepage
	const pageData = await queryClient
		.fetchQuery<PaginatedResponse<PageModule>>(
			getPageModulesQuery({ params: { page_id: 1 }, location }),
		)
		.then((res) => res)
		.catch(() => { });

	// filter results and extract modules that we will use on homepage
	if (pageData && pageData.results && pageData.results.length > 0) {
		pageData.results.forEach((module) => {
			if (module.position === 0 && pageModuleTypes.includes(module.type.name)) {
				pageModules.push(module);
			}
		});
	}

	const queries = pageModules.map((module) =>
		queryClient
			.fetchQuery(getPageModuleItemsQuery({ id: module.id, location }))
			.then((res) => res)
			.catch(() => { }),
	);

	await Promise.all([
		...queries,
		queryClient
			.fetchQuery<TopTracksResponse>(
				getTopTracksQuery({ params: { per_page: 10 }, number: 10, location }),
			)
			.then((res) => res)
			.catch(() => { }),
		queryClient
			.fetchQuery<TopTracksResponse>(
				getTopTracksQuery({
					params: { per_page: 10, hype: true },
					number: 10,
					location,
				}),
			)
			.then((res) => res)
			.catch(() => { }),
		queryClient
			.fetchQuery<PaginatedResponse<Release>>(
				getTopReleasesQuery({ params: { per_page: 10 }, number: 10, location }),
			)
			.then((res) => res)
			.catch(() => { }),
	]);

	const anonSession = await exportAnonSession();

	return {
		props: {
			dehydratedState: dehydrate(queryClient),
			pageModules: pageModules,
			anonSession,
			...(await serverSideTranslations(ctx.locale, ["translation"])),
		},
	};
}
