import { TopReleases, TopTracks } from "@components/Panels";
import FullBanner from "@components/banners/FullBanner/FullBanner";
import { SpriteIcon } from "@components/core/icons/SpriteIcon";
import {
	PageModuleChartsGrid,
	PageModuleDJCharts,
	PageModuleHeroSlider,
	PageModulePromotions,
	PageModuleReleasesGrid,
} from "@components/sliders";
import CarouselTableWrapper from "@components/tables/CarouselTable";
import { useSessionContext } from "@lib/context/session";
import { useMediaQuery } from "@lib/hooks/useMediaQuery";
import { useContext } from "react";

import TwitchLiveStream, {
	COLLAPSABLE_ID,
	TwitchHeaderControls,
	TwitchStreamContainer,
} from "@components/TwitchLiveStream";
import LiveStreamTrackList from "@components/TwitchLiveStream/LiveStreamTrackList";
import { HeadingH2 } from "@components/typography/Typography.style";
import { MY_BEATPORT_PREORDER_STORAGE_KEY } from "@lib/constants/storage-keys";
import { TwitchLiveStreamActionsContext, TwitchLiveStreamStateContext } from "@lib/context/twitchLiveStream";
import { LiveStream } from "@lib/context/twitchLiveStream/types";
import { getRecommendedTracksQuery } from "@lib/network/account";
import { getLibraryCollectionsQuery } from "@lib/network/library";
import { getLiveStreamStagesQuery, getLiveStreamsQuery } from "@lib/network/live-streams";
import { getMyBeatportTracksQuery } from "@lib/network/my-beatport";
import { PageModuleItem } from "@models/PageModule";
import { PaginatedResponse } from "@models/generics";
import { Release } from "@models/release";
import { Track } from "@models/track";
import { device } from "@styles/theme";
import { useQuery } from "@tanstack/react-query";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Fragment, useEffect, useState } from "react";
import {
	DJChartsWrapper,
	MainSection,
	MidSection,
	NewOnBeatportWrapper,
	RightSeciton,
	TopLink,
	TopSection,
	Wrapper,
} from "./Homepage.style";

const BANNER_PLACEHOLDER_SIZE = {
	maxWidth: 1200,
	aspectRatio: 4 / 2.75,
};

const STREAM_CACHE_TIME = 1000 * 60 * 5; // 5 minutes

interface Props {
	djCharts?: PageModuleItem[];
	newOnBeatport?: PageModuleItem[];
	newReleases?: PageModuleItem[];
	featuredCharts?: PageModuleItem[];
	hypePicks?: PageModuleItem[];
	marketingPromotions?: PageModuleItem[];
	top10?: Track[];
	hypeTop10?: Track[];
	top10Releases: Release[];
}

const RECOMMENDED_TRACKS_PARAMS = {
	page: 1,
	per_page: 20,
};

const MY_BEATPORT_HOMEPAGE_PARAMS = {
	page: 1,
	per_page: 20,
	order_by: "-release_date,release_id",
	include_facets: false,
	preorder: false,
};

const TwitchLiveStreamWrapper = () => {
	return (
		<TwitchStreamContainer>
			<TwitchHeaderControls />
			<Wrapper id={COLLAPSABLE_ID}>
				<MainSection>
					<TwitchLiveStream />
				</MainSection>
				<MidSection>
					<LiveStreamTrackList />
				</MidSection>
			</Wrapper>
		</TwitchStreamContainer>
	);
};

const checkIfLiveStreamIsEnabled = (liveStream: LiveStream) => {
	if (!liveStream) { return false; }

	const { enabled, is_auto_enabled, start_time, end_time } = liveStream;

	if (enabled) { return true; }

	if (is_auto_enabled && start_time && end_time) {
		const now = new Date();
		const startTime = new Date(start_time);
		const endTime = new Date(end_time);

		if (now > startTime && now < endTime) {
			return true;
		}
	}

	return false;
};

const Homepage: React.FC<Props> = ({
	newOnBeatport,
	djCharts,
	newReleases,
	featuredCharts,
	hypePicks,
	marketingPromotions,
	top10,
	hypeTop10,
	top10Releases,
}: Props) => {
	// we use device width to determine where we show top 10 panels
	const isMd = useMediaQuery({ query: device.md });
	const isSm = useMediaQuery({ query: device.sm });
	const { t } = useTranslation("translation");
	const [downloadsIds, setDownloadsIds] = useState<number[]>([]);
	const [isLiveStreaming, setIsLiveStreaming] = useState<boolean>(false);
	const { getAccessToken, getIsAnon, getIsSessionValid } = useSessionContext();
	const isAnon = getIsAnon();
	const isSessionValid = getIsSessionValid();
	const isLoggedInSessionValid = getIsSessionValid({ isAnonAllowed: false });
	const accessToken = getAccessToken();
	const { actions: { setAllStages, setCurrentStream } } = useContext(TwitchLiveStreamActionsContext);
	const { state: { currentStage, currentStream } } = useContext(TwitchLiveStreamStateContext);
	const showMyBeatportPreorders = typeof window !== "undefined" && (localStorage.getItem(MY_BEATPORT_PREORDER_STORAGE_KEY) === "true");

	const checkForLiveStreams = (liveStreams: Array<LiveStream>) => {
		const firstLiveStream = liveStreams[0];
		const shouldShowStream = checkIfLiveStreamIsEnabled(firstLiveStream);
		setIsLiveStreaming(shouldShowStream);

		if (shouldShowStream) {
			setCurrentStream(firstLiveStream);
		}
	};

	// ? Check for live stream stages if there are live streams
	useQuery<PaginatedResponse<Track>>({
		...getLiveStreamStagesQuery({
			id: currentStream.id || 1,
			accessToken,
		}),
		onSuccess: (data) => {
			setAllStages(data?.results);
		},
		enabled: isLiveStreaming,
		cacheTime: STREAM_CACHE_TIME,
		staleTime: STREAM_CACHE_TIME,
	});

	// ? Check for live streams
	useQuery<PaginatedResponse<Track>>({
		...getLiveStreamsQuery({ accessToken }),
		onSuccess: (data) => {
			checkForLiveStreams(data?.results);
		},
		cacheTime: STREAM_CACHE_TIME,
		staleTime: STREAM_CACHE_TIME,
		enabled: isSessionValid,
	});

	// only fetch my beatport tracks if user is logged in
	const myBeatportTracks = useQuery<PaginatedResponse<Track>>({
		...getMyBeatportTracksQuery({
			params: {
				...MY_BEATPORT_HOMEPAGE_PARAMS,
				preorder: showMyBeatportPreorders ? null : false,
			},
			accessToken,
		}),
		enabled: isLoggedInSessionValid,
	});

	useQuery<PaginatedResponse<Track>>({
		...getLibraryCollectionsQuery({
			params: RECOMMENDED_TRACKS_PARAMS,
			accessToken,
		}),
		onSuccess: (data) => {
			setDownloadsIds(data?.results?.map((track: Track) => track.id) || []);
		},
		enabled: isLoggedInSessionValid,
	});

	const recommendedTracks = useQuery<PaginatedResponse<Track>>({
		...getRecommendedTracksQuery({
			params: {
				page: 1,
				per_page: 20,
				ids: downloadsIds,
			},
			accessToken,
		}),
		enabled: downloadsIds.length > 0 && isLoggedInSessionValid,
	});

	useEffect(() => {
		if (downloadsIds.length > 0) {
			recommendedTracks.refetch();
		}
	}, [downloadsIds, recommendedTracks]);

	const loopcloudBanner = isSm ? "/images/banners/lc-desktop.jpg" : "/images/banners/lc-mobile.jpg";

	const pluginBoutiqueBanner = isSm ? "/images/banners/pib-desktop.png" : "/images/banners/pib-mobile.png";

	const loopcloudUrl =
		"https://sounds.loopcloud.com/label/162-Beatport-Sounds?utm_source=Beatport&utm_medium=Home+Page+Banner&utm_campaign=Beatport+Sounds?a_aid=5d6d1d2614c9f";
	const pluginBoutiqueUrl =
		"https://www.pluginboutique.com/?utm_source=Beatport&utm_medium=Home+Page+Banner&utm_campaign=Beatport+Home?a_aid=5d6d1d2614c9f";

	const rightSectionComponents = (view: "desktop" | "mobile") => (
		<>
			<TopTracks
				title={<SpriteIcon id="beatport-top-10" style={{ width: "150px", height: "20px" }} />}
				location="Home Page - Top 10 Releases"
				tracks={top10}
				isPanelList={view === "mobile"}
				footer={(
					<Link href="/top-100" prefetch={false} title="View beatport top 100 tracks">
						<TopLink>
							{`${t("Actions.View")} Beatport ${t("Top.Top100Tracks").toLocaleLowerCase()}`} <SpriteIcon id="triangle-right" />
						</TopLink>
					</Link>
				)}
				dataTestId="top-10-item"
			/>
			{marketingPromotions && <PageModulePromotions data={marketingPromotions} dataTestId="marketing-promotions" />}
			<TopTracks
				title={<SpriteIcon id="hype-top-10" width="150" height="16" style={{ width: "120px", height: "20px" }} />}
				location="Hype Top 10"
				tracks={hypeTop10}
				isPanelList={view === "mobile"}
				footer={(
					<Link href="/hype-100" prefetch={false} title="View beatport top 100 tracks">
						<TopLink>
							{`${t("Actions.View")} Hype ${t("Top.Top100Tracks").toLocaleLowerCase()}`} <SpriteIcon id="triangle-right" />
						</TopLink>
					</Link>
				)}
				dataTestId="hype-top-10-item"
			/>
			<TopReleases
				title={<HeadingH2>Top 10 Releases</HeadingH2>}
				location="Top 10 Releases"
				releases={top10Releases}
				isPanelList={view === "mobile"}
				footer={(
					<Link href="/top-100-releases" prefetch={false} title="View beatport top 100 tracks">
						<TopLink>
							{`${t("Actions.View")} Beatport ${t("Top.Top100Releases").toLocaleLowerCase()}`} <SpriteIcon id="triangle-right" />
						</TopLink>
					</Link>
				)}
				dataTestId="top-10-releases-item"
			/>
		</>
	);

	return (
		<Fragment>
			{
				currentStage && currentStage.id && (
					<TwitchLiveStreamWrapper />
				)
			}
			<Wrapper>
				<MainSection>
					<TopSection>
						<NewOnBeatportWrapper>
							<PageModuleHeroSlider
								title={t("NewOnBeatport")}
								data={newOnBeatport || []}
								negativeMargin
								dataTestId="new-on-beatport"
								bannerSizing={BANNER_PLACEHOLDER_SIZE}
							/>
						</NewOnBeatportWrapper>
						<DJChartsWrapper>
							<PageModuleDJCharts data={djCharts || []} dataTestId="dj-charts" title="DJ Charts" />
						</DJChartsWrapper>
					</TopSection>
					{!isMd && <MidSection>{rightSectionComponents("mobile")}</MidSection>}
					{myBeatportTracks?.data?.results && myBeatportTracks?.data?.results.length > 0 && !isAnon && (
						<CarouselTableWrapper
							title={t("MyBeatport")}
							tableData={myBeatportTracks?.data?.results || []}
							chunkSize={4}
							hideDots={false}
							viewAllPath="/my-beatport"
							tableType="tracks"
							dataTestId="my-beatport-tracks-row"
							showPlay
							showAddToQueue
							showAddToPlaylist
							showReleaseDate={false}
							isLoading={myBeatportTracks.isLoading}
						/>
					)}
					{recommendedTracks?.data?.results && recommendedTracks?.data?.results.length > 0 && !isAnon && (
						<CarouselTableWrapper
							title={t("Recommended")}
							tableData={recommendedTracks?.data?.results || []}
							chunkSize={4}
							hideDots={false}
							viewAllPath="/recommended"
							tableType="tracks"
							dataTestId="my-recommended-tracks-row"
							showPlay
							showAddToQueue
							showAddToPlaylist
							showReleaseDate={false}
							isLoading={recommendedTracks.isLoading}
						/>
					)}

					<PageModuleReleasesGrid
						location="Home Page - New Releases"
						title={t("NewReleases")}
						data={newReleases || []}
						dataTestId="new-releases"
					/>
					<PageModuleChartsGrid
						location="Home Page - Featured Charts"
						title={t("FeaturedCharts")}
						data={featuredCharts || []}
						dataTestId="featured-charts"
					/>
					<PageModuleReleasesGrid
						location="Home Page - Hype Picks"
						title={t("HypePicks")}
						data={hypePicks || []}
						dataTestId="hype-picks"
					/>
					<FullBanner
						title="Loopcloud"
						imageUri={loopcloudBanner}
						href={loopcloudUrl}
					/>
					<FullBanner
						title="Plugin Boutique"
						imageUri={pluginBoutiqueBanner}
						href={pluginBoutiqueUrl}
					/>
				</MainSection>
				{isMd && <RightSeciton>{rightSectionComponents("desktop")}</RightSeciton>}
			</Wrapper>
		</Fragment>
	);
};

export default Homepage;
