import styled from "styled-components";

export const PreferencesMenuNav = styled.nav`
  width: 100%;
  @media ${(props) => props.theme.device.md} {
    width: 208px;
  }
`;

export const PreferencesMenuList = styled.ul`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 1px;
  background: ${(props) => props.theme.colors.neutrals.primary[800]};
  @media ${(props) => props.theme.device.md} {
    width: 208px;
    padding: 24px 0;
  }
`;

interface PreferencesMenuListItemProps {
	active?: boolean;
}

export const StyledPreferencesMenuListItem = styled.li<PreferencesMenuListItemProps>`
  font-family: ${(props) => props.theme.fonts.aeonik};
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  height: 36px;
  padding: 8px 0 8px 16px;
  background: ${({ active, theme }) =>
		active ?
			theme.colors.neutrals.primary[700] :
			theme.colors.neutrals.primary[600]};
  border-left: ${({ active, theme }) =>
		active ? `2px solid ${theme.colors.green[300].accent}` : ""};

  &:first-child {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    border-top: ${({ active, theme }) =>
		active ? `1px solid ${theme.colors.neutrals.primary[600]}` : ""};
    border-right: ${({ active, theme }) =>
		active ? `1px solid ${theme.colors.neutrals.primary[600]}` : ""};
  }
  &:last-child {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border-bottom: ${({ active, theme }) =>
		active ? `1px solid ${theme.colors.neutrals.primary[600]}` : ""};
    border-right: ${({ active, theme }) =>
		active ? `1px solid ${theme.colors.neutrals.primary[600]}` : ""};
  }
`;
