import { PREFERENCES_MENU } from "@lib/constants/preferences";
import { useTranslation } from "next-i18next";
import { useRouter } from "next/router";
import {
	PreferencesMenuList,
	PreferencesMenuNav,
} from "./PreferencesMenu.style";
import PreferencesMenuListItem from "./PreferencesMenuListItem";

const PreferencesMenu = () => {
	const router = useRouter();
	const { t } = useTranslation("translation");
	return (
		<PreferencesMenuNav title={t("Account.Preferences")}>
			<PreferencesMenuList>
				{PREFERENCES_MENU.map(({ label, href }) => (
					<PreferencesMenuListItem
						key={label.replace(/\s/g, "")}
						label={label}
						link={href}
						isActive={router.pathname.startsWith(href)}
					/>
				))}
			</PreferencesMenuList>
		</PreferencesMenuNav>
	);
};

export default PreferencesMenu;
